import SelectVideoRange from "./SelectVideoRange";
import YoutubeComponent from "./YoutubeComponent";


export default function Test() {
    return (
        <div>

            <SelectVideoRange />

        </div>
    );
}