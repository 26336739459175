import React from "react"
import BuyCard from "components/NFTCards/BuyCard/BuyCard"
import SellCard from "components/NFTCards/SellCard/SellCard"

export default function Muhi() {

    return (
        <>
        </>
        
    )
}